import React, { Component } from "react";

export default class LoginForm extends Component {
    render() {
        return (
            <div class="pt-10 hero min-h-screen">
                <div class="card w-96 bg-base-100  border-2">
                    <div class="card-body">
                        <form action="post">
                            <div class="pb-4">
                                <label htmlFor="Title">
                                    <h1 class="text-4xl bold text-center">
                                        Login
                                    </h1>
                                </label>
                                <label htmlFor="Subtitle">
                                    {" "}
                                    <h2 class="font-light text-2xl text-center">
                                        Welcome Back
                                    </h2>
                                </label>
                            </div>

                            <div class="pb-4">
                                <input
                                    type="email"
                                    placeholder="Email"
                                    class="input input-bordered input-secondary w-full max-w-xs"
                                    disabled
                                />
                                <div class="p-2"></div>
                                <input
                                    type="password"
                                    placeholder="Password"
                                    class="input input-bordered input-secondary w-full max-w-xs"
                                    disabled
                                />
                            </div>

                            <div class="card-actions justify-center">
                                <input
                                    type="submit"
                                    class="btn btn-secondary"
                                    value="Login"
                                    disabled
                                ></input>
                            </div>
                        </form>
                        <a href="/Forgot/" class="text-center underline">
                            Forgot Password
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}
