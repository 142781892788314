import React, { useState, useEffect } from "react";
import "./PostedComments.css";
//Needs https change

const PostedComments = ({ hive, UTCDate }) => {
    const [postedComments, setPostedComments] = useState([]);

    useEffect(() => {
        const fetchComments = () => {
            fetch(
                `https://appmais.cs.appstate.edu/api/comments/retrieveComments/${hive}/${UTCDate}`,
            )
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    return response.json();
                })
                .then((data) => {
                    // console.log('Comments:', data);
                    setPostedComments(data);
                })
                .catch((error) => {
                    console.error(
                        "There was a problem with your fetch operation:",
                        error,
                    );
                });
        };
        fetchComments();
    }, []);

    return postedComments.length > 0 ? (
        <div className="posted-comments-section">
            <h2>Posted Comments</h2>
            <ul>
                {postedComments.map((comment, index) => (
                    <li key={index} className="posted-comment">
                        <p className="comment-username">{comment.Username}</p>
                        <p className="comment-text">{comment.Comment}</p>
                    </li>
                ))}
            </ul>
        </div>
    ) : (
        <div></div>
    );
};

export default PostedComments;
