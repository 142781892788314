import React from "react";
import Coming from "../../../components/Coming/Coming";

export default function Forgot() {
    return (
        <div>
            <Coming />
        </div>
    );
}
