import React from "react";
import Obj from "../../../../assets/json/Presentation.json";

export default function Presentation() {
    return (
        <section className="mt-12 max-w-screen-lg mx-auto px-4">
            <div>
                <h1 className="text-gray-800 text-6xl font-bold">
                    Presentations
                </h1>
            </div>
            <div className=" top-4">
                <br />
            </div>
            {Object.values(Obj.Presentation).map((item) => (
                <div className="pb-5">
                    <div>
                        <h2 className="text-3xl font-semibold">{item.Title}</h2>
                    </div>
                    <div>
                        <h3 className="text-xl font-thin text-gray-600 pb-3">
                            {item.Name}
                        </h3>
                    </div>
                    <div>
                        <p className="font-normal">{item.Abs}</p>
                    </div>
                </div>
            ))}
        </section>
    );
}
