import React from "react";

export default function Overview() {
    return (
        <div className="min-h-[85vh] hero">
            <div className="px-4  mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 ">
                <div className="max-w-xl sm:mx-auto lg:max-w-2xl">
                    <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                            <h1 className="text-6xl font-bold relative inline-block">
                                A Project With Purpose
                            </h1>
                        </h2>
                        <p className="text-base text-gray-700 md:text-lg">
                            With the support of a $1.1 million grant through the
                            University of North Carolina System’s Research
                            Opportunities Initiative, we have created a project
                            that can acquire, analyze, and disseminate honey bee
                            data.
                        </p>
                    </div>
                </div>
                <div className="max-w-screen-xl sm:mx-auto">
                    <div className="grid grid-cols-1 gap-16 row-gap-8 lg:grid-cols-2">
                        <div className="space-y-8">
                            <div>
                                <p className="mb-4 text-xl font-medium">
                                    How does this project work?
                                </p>
                                <p className="text-gray-700">
                                    The project utilizes a Thingsboard IoT
                                    platform and the App State designed and
                                    built “Beemon” system that acquires and
                                    delivers hive data (audio/video recordings,
                                    humidity/temperature, and weight) to our
                                    dedicated server for further analysis.
                                </p>
                            </div>
                            <div>
                                <p className="mb-4 text-xl font-medium">
                                    How many hives are active?
                                </p>
                                <p className="text-gray-700">
                                    Overall we have 30 hives. Currently, the
                                    Live Stream tab provides near-live one
                                    minute video streams of 24 hives at 12
                                    locations and the Archive tab provides
                                    videos older than six minutes from the
                                    current time for these hives.
                                </p>
                            </div>
                        </div>
                        <div className="space-y-8">
                            <div>
                                <p className="mb-4 text-xl font-medium">
                                    What type of technologies are being used and
                                    who is using them?
                                </p>
                                <p className="text-gray-700">
                                    We are utilizing machine learning and
                                    bioinformatics tools to answer key questions
                                    on hive health, development, and genomic
                                    diversity. The project provides significant
                                    training opportunity for faculty, students,
                                    and beekeepers.
                                </p>
                            </div>
                            <div>
                                <p className="mb-4 text-xl font-medium">
                                    Where can I see the project?
                                </p>
                                <p className="text-gray-700">
                                    There are many places you can see our
                                    project in action. In order to look at the
                                    live-stream of the bees navigate to
                                    Beestream and select Live Stream. To see the
                                    archive of bee data, navagate to Beestream
                                    and select archive.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
