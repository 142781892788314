import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import "./App.css";

import Nav from "./components/Nav/Nav";
import Home from "./pages/public/Home/Home";
import Overview from "./pages/public/Research/Overview/Overview";
import Publications from "./pages/public/Research/Publications/Publications";
import Login from "./pages/public/Login/Login";
import Live from "./pages/public/Beestream/Live/Live";
import Archive from "./pages/public/Beestream/Archive/Archive";
import Forgot from "./pages/public/Forgot/Forgot";
import Teams from "./pages/public/Teams/Teams";
import Media from "./components/Media/Media";
import Presentation from "./pages/public/Research/Presentations/Presentation";
import Footer from "./components/Footer/Footer";
import InterestingEvents from "./pages/public/InterestingEvents/InterestingEvents";
import Flowers from "./pages/public/Flowers/Flowers";

/**
 * @file App.js is the root of the application and contains the router.
 * @see https://reactrouter.com/en/6.17.0/start/overview React Router Dom
 * Follow the web extensions on the website to see components being rendered.
 *
 */

function App() {
    return (
        <Router class="Router">
            <Nav />
            <Routes>
                <Route path="/" element={<Home />}></Route>
                <Route path="/Overview/" element={<Overview />}></Route>
                <Route path="/Publications/" element={<Publications />}></Route>
                <Route path="/Login/" element={<Login />}></Route>
                <Route path="/Live/" element={<Live />}></Route>
                <Route path="/Archive/" element={<Archive />}></Route>
                <Route path="/Forgot/" element={<Forgot />}></Route>
                <Route path="/Teams/" element={<Teams />}></Route>
                <Route path="/Media/" element={<Media />}></Route>
                <Route path="/InterestingEvents/" element={<InterestingEvents />}></Route>
                <Route path="/Flowers/" element={<Flowers />}></Route>
                <Route
                    path="/Presentations/"
                    element={<Presentation />}
                ></Route>
            </Routes>
            <Footer />
        </Router>
    );
}

export default App;
