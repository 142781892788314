import React from "react";
import TeamsSection from "../../../components/Teams/TeamsSection";
import "./Teams.css";

export default function Teams() {
    return (
        <div className="custom-hero-teams">
            <h1>Meet Our Team</h1>
            <TeamsSection sectionTitle={"Principle Investigators"} />
            <TeamsSection sectionTitle={"Research & Support Staff"} />
            <TeamsSection sectionTitle={"Graduate Student Researchers"} />
            <TeamsSection sectionTitle={"Undergraduate Student Researchers"} />
            <TeamsSection sectionTitle={"Former Student Researchers"} />
        </div>
    );
}
