import React, { Component } from "react";
import IframeResizer from "iframe-resizer-react";

class Live extends Component {
    render() {
        return (
            <div>
                <IframeResizer
                    log
                    heightCalculationMethod="bodyScroll"
                    src="https://beemon.cs.appstate.edu/stream"
                    scrolling="yes"
                    style={{ width: "1px", minWidth: "100%", height: "85vh" }}
                />
            </div>
        );
    }
}

export default Live;
