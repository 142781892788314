import React from "react";
import LoginForm from "../../../components/LoginForm/LoginForm";

export default function Login() {
    return (
        <div className="min-h-[85vh]">
            <LoginForm />
        </div>
    );
}
