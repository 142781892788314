import React from "react";
import MemberS from "../Member/MemberS/MemberS";

import TeamID from "../../assets/json/Team.json";

export default function TeamsSection(props) {
    return (
        <div>
            <div className="p-10"></div>
            <h3 className="text-3xl font-bold pb-6 text-center">
                {props.sectionTitle}
            </h3>

            <div className="flex flex-wrap w-full lg:flex-row pb-4">
                {Object.values(TeamID.Team[props.sectionTitle]).map((obj) => {
                    return (
                        <div className="grid flex-grow rounded-box place-items-center pb-4 px-2">
                            <MemberS
                                title={obj.Name}
                                degree={obj.Degree}
                                image={obj.Image}
                                summary={obj.Summary}
                                link={obj.Link}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
