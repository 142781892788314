import React from "react";
import Hero from "../../../components/Hero/Hero";

export default function Home() {
    return (
        <div className="min-h-[85vh]">
            <Hero />
        </div>
    );
}
