import React from "react";

export default function MemberS(props) {
    return (
        <div className="card card-compact w-60 bg-base-100 shadow-xl">
            <figure>
                <img className="" src={props.image} alt="" />
            </figure>
            <div className="card-body h-32 text-center">
                <a
                    href={props.link}
                    className="text-lg bold text-center hover:underline"
                >
                    {props.title}
                </a>
            </div>
            <div className="p-1"></div>
        </div>
    );
}
