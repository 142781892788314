import React, { useState, useEffect } from 'react';
import './Mp4Streamer.css'

const Mp4Streamer = ({ hiveName, date, time }) => {

    const [VideoUrl, setVideoUrl] = useState('')
    const fetchVideoFilePath = (selectedHive, selectedDay, selectedTime) => {
        fetch(`https://appmais.cs.appstate.edu/api/videofiles/${selectedHive}/${selectedDay}/${convertTimeToQuery(selectedTime)}/filepath`)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to fetch hives');
                }
            })
            .then(data => {
                setVideoUrl("https://appmais.cs.appstate.edu/videos/" + createSpecificVideoUrl(data.FilePath) );
                // console.log("VideoURL: " + this.state.videoUrl)
                // console.log("data: " + data.FilePath)
            })
            .catch(error => {
                this.setState({ error: error.toString() });
            });
    }


    const createSpecificVideoUrl = (filePath) => {
        // Split the path at 'appmais/' and take the part after it
        const splitPath = filePath.split('appmais/');
        const latterHalfPath = splitPath.length > 1 ? splitPath[1] : '';

        // Replace the file extension from .h264 to .mp4
        return latterHalfPath.replace(/\.h264$/, ".mp4");
    }


    const convertTimeToQuery = (timeString) => {
        // Extract hours, minutes, seconds, and am/pm parts from the string
        const [time, amOrPm] = timeString.split(' ');
        const [hoursString, minutesString, secondsString] = time.split(':');
        let hours = parseInt(hoursString, 10);

        // Convert 12-hour hours back to 24-hour format
        if (amOrPm === 'pm' && hours !== 12) {
            hours += 12;
        }
        if (amOrPm === 'am' && hours === 12) {
            hours = 0;
        }

        return `${String(hours).padStart(2, '0')}:${minutesString}:${secondsString}`;
    }

    // Use useEffect to call fetchVideoFilePath when hiveName, date, or time changes
    useEffect(() => {
        if (hiveName && date && time) {
            fetchVideoFilePath(hiveName, date, convertTimeToQuery(time));
        }
    }, [hiveName, date, time]);

    return (
        <div>
            <video className="video-div" id="video" src={VideoUrl} controls autoPlay>
                Your browser doesn't support HTML5 video.
            </video>
        </div>
    );
};

export default Mp4Streamer;