import React from "react";

export default function Coming() {
    return (
        <div>
            <div className="hero min-h-[85vh]">
                <div className="flex flex-col w-full">
                    <h1 className="text-6xl font-bold pb-4 text-center">
                        Coming Soon
                    </h1>
                </div>
            </div>
        </div>
    );
}
