import React, { Component } from "react";
import "./Archive.css";
import InterestingJSON from "../../../../assets/json/InterestingEvents.json";
import Comments from "./../../../../components/Comments/Comments";
import PostedComments from "../../../../components/PostedComments/PostedComments";
import LabelTagButton from "../../../../components/LabelTagButton/LabelTagButton";

export default class BeeStreamArchive extends Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.SharableVideoLink = this.SharableVideoLink.bind(this);

        this.state = {
            hiveSelect: "",
            dateSelect: "",
            timeSelect: "",
            hivelst: [],
            selection: [],
            videoLoaded: true,
            videoExists: true,
            videoFetchAttempted: false,
            videoUrl: "",
            videoBasedUrl: "https://appmais.cs.appstate.edu/videos/",
            h264Url: "",
            error: null,
            daylst: [],
            timelst: [],
            secondDropDis: false,
            thirdDropDis: false,
            dropDownCss:
                "px-1 text-sm bg-gray-100 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 " +
                "focus:ring focus:ring-indigo-200 focus:ring-opacity-50 mt-4",
            beeThemedButton:
                "bg-yellow-400 text-black font-semibold px-4 py-2 rounded-lg hover:bg-yellow-500 " +
                "focus:outline-none focus:ring-2 focus:ring-yellow-300 focus:ring-opacity-50 mt-7",
        };
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({
            selection: [
                this.state.hiveSelect,
                this.state.dateSelect,
                this.state.timeSelect,
            ],
        });
        this.fetchVideoFilePath(
            this.state.hiveSelect,
            this.state.dateSelect,
            this.state.timeSelect,
        );
    }

    componentDidMount() {
        fetch(`https://appmais.cs.appstate.edu/api/hives`)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("Failed to fetch hives");
                }
            })
            .then((data) => {
                this.setState({ hivelst: data });
            })
            .catch((error) => {
                this.setState({ error: error.toString() });
            });

        const queryParams = new URLSearchParams(window.location.search);
        const hiveS = queryParams.get("hiveSelect");
        const dateS = queryParams.get("dateSelect");
        const timeS = queryParams.get("timeSelect");

        if (hiveS && dateS && timeS) {
            this.setState(
                {
                    hiveSelect: hiveS,
                    dateSelect: dateS,
                    timeSelect: timeS,
                    secondDropDis: true,
                    thirdDropDis: true,
                },
                () => {
                    this.setState({
                        selection: [
                            this.state.hiveSelect,
                            this.state.dateSelect,
                            this.state.timeSelect,
                        ],
                    });
                    this.fetchVideoFilePath(hiveS, dateS, timeS);
                },
            );
        }
    }

    fetchDays(hive) {
        fetch(`https://appmais.cs.appstate.edu/api/videofiles/${hive}/days/`)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("Failed to fetch hives");
                }
            })
            .then((data) => {
                this.setState({ daylst: data });
                //console.log(data);
            })
            .catch((error) => {
                this.setState({ error: error.toString() });
            });
    }

    fetchTimes(selectedDay, hive) {
        fetch(
            `https://appmais.cs.appstate.edu/api/videofiles/${hive}/times/${this.convertDate(selectedDay)}`,
        )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("Failed to fetch hives");
                }
            })
            .then((data) => {
                this.setState({ timelst: data });
            })
            .catch((error) => {
                this.setState({ error: error.toString() });
            });
    }

    //Checks to see if a video at given URL Exists
    checkVideoExists = (url) => {
        return fetch(url, { method: "HEAD" }) //Checking the header and not the entire body
            .then((response) => {
                if (response.ok) {
                    return true; // Video exists
                }
            })
            .catch((error) => {
                console.error("Error checking video existence:", error);
                return false; // Video does not exist
            });
    };

    //Fetches Video Filepath based on selected Hive, Day, and Time
    fetchVideoFilePath(selectedHive, selectedDay, selectedTime) {
        //console.log("hive: " + selectedHive + " Day: " + selectedDay+ " time: " + selectedTime);
        fetch(
            `https://appmais.cs.appstate.edu/api/videofiles/${selectedHive}/${this.convertDate(selectedDay)}/${this.convertTimeTo24HourFormat(selectedTime)}/filepath`,
        )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("Failed to fetch hives");
                }
            })
            .then((data) => {
                const videoPathUrl =
                    this.state.videoBasedUrl +
                    this.createSpecificVideoUrl(data.FilePath); //constructing MP4 URL
                this.setState({ h264Url: data.FilePath });
                this.checkVideoExists(videoPathUrl).then((exists) => {
                    if (exists) {
                        this.setState({
                            videoUrl: videoPathUrl,
                            videoLoaded: true,
                            videoExists: true,
                            videoFetchAttempted: true,
                        });
                    } else {
                        this.setState({
                            videoLoaded: false,
                            videoExists: false,
                            videoFetchAttempted: true,
                        });
                    }
                });
            })
            .catch((error) => {
                this.setState({
                    error: error.toString(),
                    videoLoaded: false,
                    videoExists: false,
                    videoFetchAttempted: true,
                });
                //console.log("Loaded: " + this.state.videoLoaded + " Exists: " + this.state.videoExists + " Attempt: " + this.state.videoFetchAttempted)
            });
    }

    //Sets VideoExists and videoLoaded to false if video does not load in HTML
    videoErrorHandler = () => {
        this.setState({ videoExists: false, videoLoaded: false });
    };

    /*
        Creates the specifics of the url needed for the endpoint
     */
    createSpecificVideoUrl(filePath) {
        // Split the path at 'appmais/' and take the part after it
        const splitPath = filePath.split("appmais/");
        const latterHalfPath = splitPath.length > 1 ? splitPath[1] : "";

        // Replace the file extension from .h264 to .mp4
        return latterHalfPath.replace(/\.h264$/, ".mp4");
    }
    /* Changes date strings like 02/18/2003 to 02-18-2003 for query */
    convertDate = (dateStr) => {
        const [month, day, year] = dateStr.split("/");
        return `${year}-${month}-${day}`;
    };

    handleHiveChange = (e) => {
        this.setState({ hiveSelect: e.target.value }, () => {
            this.fetchDays(this.state.hiveSelect);
        });
        this.setState({
            secondDropDis: !!e.target.value,
            secondDropdownSelected: false,
        });
    };

    handleDateChange = (e) => {
        this.setState({ dateSelect: e.target.value }, () => {
            this.fetchTimes(this.state.dateSelect, this.state.hiveSelect);
        });
        this.setState({ thirdDropDis: !!e.target.value });
    };

    handleTimeChange = (e) => {
        this.setState({ timeSelect: e.target.value });
    };

    /*
        Converts a readable time String (2:35:00 pm) to 24-Hour clock format (14:35:00)
     */
    convertTimeTo24HourFormat = (timeString) => {
        // Extract hours, minutes, seconds, and am/pm parts from the string
        const [time, amOrPm] = timeString.split(" ");
        const [hoursString, minutesString, secondsString] = time.split(":");
        let hours = parseInt(hoursString, 10);

        // Convert 12-hour hours back to 24-hour format
        if (amOrPm === "pm" && hours !== 12) {
            hours += 12;
        }
        if (amOrPm === "am" && hours === 12) {
            hours = 0;
        }

        return `${String(hours).padStart(2, "0")}:${minutesString}:${secondsString}`;
    };

    /*
        Returns a UTCDate in the format of 2023-10-18T15:55:00.000Z
     */
    convertDateTime = (dateStr, timeStr) => {
        const convertedTime = this.convertTimeTo24HourFormat(timeStr);

        // Split the converted time to separately adjust hours
        let [hours, minutes, seconds] = convertedTime.split(":");
        hours = parseInt(hours, 10) + 4; // Add 4 hours for timezone adjustment

        // Handle overflow of hours into the next day
        if (hours >= 24) {
            hours -= 24;
        }

        // Reconstruct the time string with adjusted hours
        const adjustedTime = `${hours.toString().padStart(2, "0")}:${minutes}:${seconds}`;
        return `${dateStr}T${adjustedTime}.000Z`;
    };

    SharableVideoLink(e) {
        e.preventDefault();
        const queryParams = new URLSearchParams();
        queryParams.append("hiveSelect", this.state.hiveSelect);
        queryParams.append("dateSelect", this.state.dateSelect);
        queryParams.append("timeSelect", this.state.timeSelect);
        const SharableLink = `${window.location.origin}${window.location.pathname}?${queryParams}`;
        console.log(SharableLink);
        navigator.clipboard.writeText(SharableLink).then(() => {
            alert("Link Copied to Clipboard!");
        });
    }

    render() {
        //This is an example of an implicit return in jsx, no need for curly braces after the arrow or a return
        const hiveOptions = Object.values(this.state.hivelst).map((tag) => (
            //{console.log(tag)}
            <option key={tag}>  {tag}  </option>
        ));
        const dateOptions = Object.values(this.state.daylst.reverse()).map((tag) => (
            <option key={tag}> {tag} </option>
        ));
        const timeOptions = Object.values(this.state.timelst.reverse()).map((tag) => (
            <option key={tag}> {tag} </option>
        ));
        const tagButtons = this.state.h264Url && this.state.videoUrl && Object.values(InterestingJSON.TagList).map((tag) => (
            <LabelTagButton
                label={tag}
                filePath={this.state.h264Url}
                UTCDate={this.convertDateTime(this.convertDate(this.state.dateSelect), this.state.timeSelect)}
                hiveName={this.state.hiveSelect}
            />
        ));

        const videoTitle = this.state.videoUrl && this.state.videoLoaded && this.state.videoExists ? (
            `Video from ${this.state.selection[0]} on ${this.state.selection[1]} at ${this.state.selection[2]}`
        ) : null;


        return (
            <div className="flex-container">
                <div className="sect main-content">
                    <div className="min-h-[85vh]">
                        <h1 className="font-bold text-4xl text-center pb-3">Archive</h1>
                        <form>
                            <div className="dropdown-div">
                                <select
                                    name="HiveSelect"
                                    onChange={this.handleHiveChange}
                                    className={`dropdown ${this.state.dropDownCss}`}
                                >
                                    <option value="">Choose a Hive</option>
                                    {hiveOptions} {/*All the hive dropdown menu options*/}
                                </select>

                                <select
                                    name="dateSelect"
                                    onChange={this.handleDateChange}
                                    className={`dropdown ${this.state.dropDownCss}`}
                                    disabled={!this.state.secondDropDis}
                                >
                                    <option value="">Choose a Date</option>
                                    {dateOptions} {/*All the date dropdown menu options*/}
                                </select>

                                <select
                                    name="timeSelect"
                                    onChange={this.handleTimeChange}
                                    className={`dropdown ${this.state.dropDownCss}`}
                                    disabled={!this.state.thirdDropDis}
                                >
                                    <option value="">Choose a Time</option>
                                    {timeOptions} {/*All the time dropdown menu options*/}
                                </select>
                            </div>

                            <button className={this.state.beeThemedButton} onClick={this.onSubmit}>Submit</button>
                        </form>

                        <div className="video-div">
                            {videoTitle ? (
                                <>
                                    <h2 className="font-bold text-2xl text-center mt-3 mb-3 pb-3">{videoTitle}</h2>
                                    <video className="video-div" id="video"
                                           src={this.state.videoUrl} controls
                                           autoPlay onError={this.videoErrorHandler}>
                                        Your browser doesn't support HTML5 video.
                                    </video>

                                    <button className="UrlButton" onClick={this.SharableVideoLink}> Link🔗</button>

                                    <div className="button-tags-container">
                                        {tagButtons}
                                    </div>
                                    <Comments
                                        hive={this.state.hiveSelect}
                                        filePath={this.state.h264Url}
                                        utcDate={this.convertDate(this.state.dateSelect)}
                                        utcTime={this.convertTimeTo24HourFormat(this.state.timeSelect)}
                                    />
                                    <PostedComments
                                        hive = {this.state.hiveSelect}
                                        UTCDate={this.convertDateTime(this.convertDate(this.state.dateSelect), this.state.timeSelect)}
                                    />
                                </>
                            ) : this.state.videoFetchAttempted && !this.state.videoExists ? (
                                <h2 className="font-bold text-2xl text-center mt-3 mb-3 pb-3">
                                    Uh oh! No Video found from {this.state.selection[0]} on {this.state.selection[1]} at {this.state.selection[2]}
                                </h2>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
