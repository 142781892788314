import React from "react";
import news from "../../assets/videos/apptv-news.mp4";

/**
 * The Media component is what is rendered when a user navigates to /media.
 * Currently, it just contains a single video, but work is being done to expand it to include articles and other
 * external media about the project.
 * @returns {React.Component}
 */
export default function Media() {
    return (
        <div class="min-h-[85vh]">
            <h1 class="font-bold text-3xl text-center pb-3">Media</h1>
            <center>

                <video width="500" height="500" className="justify-center" controls>
                    <source src={news} type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
            </center>
        </div>
    );
}
