import  React, { Component, useRef } from "react";
import "./Event.css"
import ReactAudioPlayer from 'react-audio-player'
import aud from "../../assets/media/AppMAIS2L@2023-08-08@14-30-00.wav"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faDownLeftAndUpRightToCenter} from '@fortawesome/free-solid-svg-icons';

import Mp4Streamer from "../Mp4Streamer/Mp4Streamer";
/**
 *  This is the Interesting Events component which shows a model of an Event Card. The card highlights an interesting
 *  video captured on the BeeStream. Along with the associated audio file, it also contains a detailed description, the
 *  Hive name, and the date and time the event occurred.
 */

export default class Event extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: false, // Initialize state based on props
			audioRef: React.createRef(),
			date: '',
			time: '',
		};
	}

	componentDidMount() {
		this.splitTimestamp(this.props.timestamp)

	}
	/**
	 * 	Function to set an expanded state for each individual card.
 	 */
	setExpanded = () => {
		this.setState({ expanded: !this.state.expanded });
		// console.log("state toggled to " + this.state.expanded);
	}

	splitTimestamp = (Timestamp) => {
		// Check if Timestamp is defined
		if (!Timestamp) {
			console.error("Timestamp is undefined");
			return;
		}

		// Parse the timestamp
		const parsedTimestamp = new Date(Timestamp);

		// Extract the date and time in UTC
		const isoString = parsedTimestamp.toISOString();
		const extractedDate = isoString.split('T')[0];
		const extractedTime = isoString.split('T')[1].split('.')[0];

		// Update the state
		this.setState({
			date: extractedDate,
			time: extractedTime
		});
	}


	/**
	 * Allows functionality for expanded and unexpanded card state.
	 * @returns {JSX.Element}
	 */
	render()  {
		const { expanded } = this.state;
			return expanded ? (
				<div className="interesting-event">
					<div className="min-button-row">
						<button className="min-button" onClick={this.setExpanded} >
							<FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} />
						</button>
					</div>

					<div className="title-date-time-row">
						<u><p className="title-info"> {this.props.hivename} </p></u>
						<div className="expanded-info">
							<h> {this.state.date} </h>
							<h4> {this.state.time}</h4>
						</div>
					</div>

					<div className="interesting-video">
						<Mp4Streamer hiveName={this.props.hivename} time={this.state.time} date={this.state.date}  />
					</div>


					<div className="description-box" >
						<div className="description-expanded" >
							<text className="text">{this.props.description}</text>
						</div>
					</div>
				</div>
			)
				: (  //Shows the unexpanded view of the card component but allows the card to be expanded onclick
						<div className="event-preview" onClick={this.setExpanded}>
							<div className="title-info-preview">

								<h1> <strong><u>{this.props.hivename}</u></strong> </h1>

								<h>Date: {this.state.date} </h>
								<h4>Time: {this.state.time}</h4>
							</div>

							<div className="description-box1" >
								<div className="description-event" >
									<p className="text">{this.props.eventTitle}</p>
								</div>
							</div>

						</div>
				)
	}

}
