import React, { useState } from "react";
import "./Comments.css";
import axios from "axios";
import moment from "moment-timezone";
const Comments = ({ hive, filePath, utcDate, utcTime }) => {
    const [commentData, setCommentData] = useState({
        Hive: hive,
        FilePath: filePath,
        Comment: "",
        Username: "",
    });

    const handleInputChange = (e) => {
        setCommentData({ ...commentData, [e.target.name]: e.target.value });
    };
    const [showSubmissionMessage, setShowSubmissionMessage] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault(); // Stops page from reloading
        console.log("Date: " + utcDate + " Time: " + utcTime);
        const combinedUTCDateTime = new Date(`${utcDate}T${utcTime}Z`);
        const payload = {
            ...commentData,
            Created: moment().tz("America/New_York").toDate(),
            UTCDate: combinedUTCDateTime, // UTC time
        };
        try {
            // axios being used to get response from sending the payload to endpoint
            const response = await axios.post(
                "https://appmais.cs.appstate.edu/api/comments",
                payload,
            );
            console.log(response.data);
            // Clear input fields by resetting commentData state
            setCommentData({ ...commentData, Comment: "", Username: "" });
            // Show submission message
            setShowSubmissionMessage(true);
            //Hide message after 3 seconds
            setTimeout(() => setShowSubmissionMessage(false), 3000);
        } catch (error) {
            console.error("Error submitting comment:", error);
        }
    };

    return (
        <form className="form-comment" onSubmit={handleSubmit}>
            <input
                type="text"
                name="Username"
                value={commentData.Username}
                onChange={handleInputChange}
                placeholder="Username"
            />

            <textarea
                name="Comment"
                value={commentData.Comment}
                onChange={handleInputChange}
                placeholder="Comment"
                rows="2" // Sets the initial visible number of lines in the textarea
            />
            <button type="submit">Submit Comment</button>
            {showSubmissionMessage && (
                <div
                    className={`submission-message ${!showSubmissionMessage ? "fade-out" : ""}`}
                >
                    Your comment has been submitted!
                </div>
            )}
        </form>
    );
};
export default Comments;
